import React, { useEffect, useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { fetchOrder } from '../http/item';

const OrderInfo = () => {
    const param = useParams()
    const [text, setText] = useState('Загрузка...');

    useEffect(() => {
        fetchOrder(param.orderid.replace('-', '/')).then(data => {
            setText(data)
        })
    }, [])
    return (

        <Container className='mh100 '>
            <Row>
                <div className="container" style={{padding: '24px'}}>
                <div className="row">
                <div className="col">
                    <h5>
                    Убедительная просьба проверить выведенные вами данные на заказ, в случае если есть ошибки в дате,
                    адресе, времени, в телефоне получателя связаться с нами по номеру телефона <a href="tel:89123939150">8 (912) 393-91-50</a> Для исправления их</h5></div>
                    </div>
                    </div>
                <div dangerouslySetInnerHTML={{ __html: text }}></div>



<div>

</div>
</Row>
			</Container>

    );

}

export default OrderInfo;