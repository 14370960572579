import { Observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Col, Container , Row, Button} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Context } from '..';
import CategoryItem from '../components/CategoryItem';
import TypeButton from '../components/TypeButton';

const Category = () => {
    const {product} = useContext(Context)
    const param = useParams()
    useEffect(() => {
        //product.setSelectedType =  param.slug 
        
    }, [])
    console.log(param.slug +' != ' + product.selectedType + ' ' + (param.slug  !=  product.selectedType))
    if(param.slug != product.selectedType )
        product.setSelectedType(param.slug )
    return (
        <>

            <Container className='mh100 container-fluid container-md '>
            

            <CategoryItem />
            
            
            </Container>
        </>

    );

}

export default Category;