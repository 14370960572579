import React, {useContext, useRef, useState} from 'react';
import { observer } from "mobx-react-lite"
import { Button, Modal, Nav } from "react-bootstrap"
import { Context } from '..';
import { useParams, useNavigate } from 'react-router-dom';
import { CATEGORY_ROUTE } from '../utils/consts';
import axios from 'axios';


const FileUploader =  observer(() => {
    const { cart } = useContext(Context)
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploaded, setUploaded] = useState();
    const filePicker = useRef(null)
    const handleChange = async (event) =>{

        const formData = new FormData()
        formData.append('file', event.target.files[0]) 



        axios.request({
            method: "post", 
            url: "https://xn--72-mlcaj1bkiw.xn--p1ai/api/video.php", 
            data: formData, 
            onUploadProgress: (p) => {
                setLoaded((p.loaded / p.total)*100)
              console.log(p); 
              //this.setState({
                  //fileprogress: p.loaded / p.total
              //})
            }
        }).then (data => {
            setLoaded(100)
            console.log(data)
            cart.setVideo(data)
            //this.setState({
              //fileprogress: 1.0,
            //})
        })

       /* const res = await fetch('https://xn--72-mlcaj1bkiw.xn--p1ai/api/video.php', {
            method: 'POST',
            body: formData
        })*/
        console.log(event.target.files)
    }


    const [show, setShow] = useState(false);

    const [loaded, setLoaded] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handlePicVideo = () =>{
        filePicker.current.click()
    }

    return(<>
        <div className="d-flex flex-row justify-content-start align-items-center pe-3 mb-4">

            
            
                <button class="cart-btn" onClick={handlePicVideo}>Добавить видеопоздравление</button>
                <input 
                    ref={filePicker}
                    type="file"
                    onChange={handleChange}
                    accept="video/mp4,video/x-m4v,video/*"
                    placeholder="Имя получателя"
                    className="mb-4 hidden"
            />
           

            <div style={{cursor: 'pointer', marginLeft: '15px', textDecoration: 'underline'}} onClick={handleShow}>Как это работает?</div>    


  


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Видео поздравление. Как это работает? </Modal.Title>
                </Modal.Header>
                <Modal.Body>  
                1. Ты записываешь на свой смартфон видео. И прекрепляешь его в специально отведенное окошко "добавить видео поздравление". <br />
2. Мы распечатываем QR код, прямую ссылку на Твоё видео. И отдаём его в руки получателю. <br />
3. И начинается самый трогательный момент. Получатель наводит своим телефоном на QR код и смотрит Твоё поздравление. </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Понятно
                </Button>
                
                </Modal.Footer>
            </Modal>
        </div>
        <div className={`progress ${loaded == 0 ? 'hidden' : ''}  `}   >
            <div className="progress-bar" role="progressbar" style={{width: loaded+'%', background:'#ff4081'}} aria-valuenow={loaded} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        </>
    )
})
export default FileUploader