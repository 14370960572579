import { makeAutoObservable } from "mobx"

export default class OrdersStore {
    constructor() {
        const ls = localStorage.getItem('orders')
        this._orders = ls === null ? [] : JSON.parse( ls)
      
        makeAutoObservable(this)
    }

    addOrder(orderNumber, items, status, amount) {
        let inArray = false
        this._orders.forEach(el => {
            if(el.orderNumber == orderNumber) inArray = true
        })
        if(!inArray)
            this._orders.push({orderNumber: orderNumber, items: items, status: status, amount: amount}) 
        
        localStorage.setItem('orders', JSON.stringify(this._orders) );
    }
    


    get count() {
        return this._orders.length
    }
    get orders(){
        return this._orders
    }

}