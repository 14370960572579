import { useContext, useState } from "react"
import { Button, Col, Container, Nav, Navbar, Offcanvas } from "react-bootstrap"
import { Context } from ".."
import logo from '../assets/img/logo.png'
import logo_full from '../assets/img/logo_full.png'
import logo_full_m from '../assets/img/logo_full_m.png'
import logo_sm from '../assets/img/logo-sm.png'
import cartImg from '../assets/img/basket.png'
import cartFullImg from '../assets/img/basket-full.png'
import { CART_ROUTE, CATEGORY_ROUTE, CONTACT_ROUTE, PAYMENT_ROUTE, REVIEWS_ROUTE, DELIVERY_ROUTE, CARE_ROUTE } from "../utils/consts"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import { declOfNum } from "../utils/functions"
import TypeButton from "./TypeButton"


const NavBar = observer(() => {
    const { cart, product } = useContext(Context)
    const navigate = useNavigate()

    const [expanded, setExpanded] = useState(false);

    const handleOnClick = (i) => {
        if (i !== product.selectedType) {
            product.setProducts([])
            //product.setSelectedType(i)
        }

        navigate(CATEGORY_ROUTE + '' + i)
    }
    return (
        <>
            <div className="w-100">
                <Nav className="m-auto d-flex d-md-none justify-content-around bg-white">
                    <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(PAYMENT_ROUTE)} >Оплата</Nav.Link>
                    <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(DELIVERY_ROUTE)}>Доставка</Nav.Link>
                    <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(CARE_ROUTE)}>Уход</Nav.Link>
                    <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(CONTACT_ROUTE)}>Контакты</Nav.Link>
                </Nav>
            </div>
            <Navbar bg="white" variant="light" >
                <Container>

                    <Navbar.Brand style={{ cursor: 'pointer' }}>

                        <img onClick={() => handleOnClick(0)}
                            alt=""
                            src={logo_full}
                            className="d-none d-lg-block"
                            height='33px'
                            lassName="d-inline-block align-top"
                        />{' '}

                    </Navbar.Brand>

                    <Navbar.Brand style={{ cursor: 'pointer' }}>

                        <img onClick={() => handleOnClick(0)}
                            alt=""
                            src={logo_full_m}
                            className="d-block d-lg-none"
                            height='33px'
                            lassName="d-inline-block align-top"
                        />{' '}

                    </Navbar.Brand>

                    <Navbar.Collapse id="responsive-navbar-nav">

                        <Nav className="m-auto d-none d-md-flex">
                            <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(PAYMENT_ROUTE)} >Оплата</Nav.Link>
                            <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(DELIVERY_ROUTE)}>Доставка</Nav.Link>
                            <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(CARE_ROUTE)}>Уход</Nav.Link>
                            <Nav.Link style={{ fontSize: '12px' }} onClick={() => navigate(CONTACT_ROUTE)}>Контакты</Nav.Link>
                        </Nav>
                        <div className="m-auto d-flex d-md-none "></div>
                        <div className="d-none d-lg-flex align-items-baseline">
                            <div style={{ fontSize: '12px', marginRight: '15px' }}>Доставляем по Тобольску</div>
                            <div ><a style={{ textDecoration: 'none', fontWeight: '500', color: '#333' }} href='tel:89123939150'>8 (912) 393-91-50</a></div>
                        </div>

                        <div className="d-flex d-lg-none flex-column-reverse">
                            <div style={{ fontSize: '10px', marginRight: '5px' }}>Доставляем по Тобольску</div>
                            <div ><a style={{ textDecoration: 'none', fontWeight: '500', color: '#333' }} href='tel:89123939150'>8 (912) 393-91-50</a></div>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>



            <Navbar className="d-none d-xxl-flex sticky-top" collapseOnSelect expand="lg" bg="light" variant="light">
                <Container >

                    <div style={{ color: ' rgba(0,0,0,.55)', fontSize: '14px', marginRight: '10px', padding: '3px', border: '2px solid rgba(0,0,0,.35)', borderRadius: '5px', cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center' }} aria-controls="offcanvasNavbar" onClick={() => setExpanded(expanded ? false : "expanded")} >
                        <span class="navbar-toggler-icon"></span>
                        <div style={{ fontSize: '16px', }}>Каталог</div>
                    </div>
                    <Navbar.Collapse id="responsive-navbar-nav m-auto">
                        <TypeButton setExp={() => { setExpanded(expanded ? false : "expanded") }} horisontal />


                    </Navbar.Collapse>

                    <div className="d-flex">

                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => navigate(CART_ROUTE)}>
                            <img
                                alt=""
                                width="24px"
                                height='24px'
                                src={cart.count > 0 ? cartFullImg : cartImg}
                                className="d-inline-block align-top"
                            />{' '}
                            <div className="d-flex flex-column" style={{ marginLeft: '.25rem' }} >
                                <div style={{ fontSize: '12px', lineHeight: '17px', textTransform: 'uppercase' }}>Корзина</div>
                                <div className={cart.count > 0 ? 'menu-basket-text color-pink' : 'menu-basket-text'}  >{cart.count > 0 ? cart.count + declOfNum(cart.count, [' Товар', ' Товара', ' Товаров']) : 'Нет товаров'} </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>


            {
                // Мобилка
            }
            <Navbar className="d-flex d-xxl-none sticky-top" bg="light" expanded={expanded} expand={false} collapseOnSelect>
                <Container fluid>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => setExpanded(expanded ? false : "expanded")} />


                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="start"
                    >
                        <Offcanvas.Header >
                            <Offcanvas.Title id="offcanvasNavbarLabel" >ПРЕЗЕНТ</Offcanvas.Title>
                            <button type="button" class="btn-close" aria-label="Close" onClick={() => setExpanded(expanded ? false : "expanded")}></button>
                        </Offcanvas.Header>
                        <Offcanvas.Body>

                            <TypeButton setExp={() => { setExpanded(expanded ? false : "expanded") }} />
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>

                    <div className="m-auto" style={{ cursor: 'pointer', }} onClick={() => navigate(CATEGORY_ROUTE) + '/0'}>ПРЕЗЕНТ</div>

                    <div className="d-flex">

                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '1rem' }} onClick={() => navigate(CART_ROUTE)}>
                            <img
                                alt=""
                                width="24px"
                                height='24px'
                                src={cart.count > 0 ? cartFullImg : cartImg}
                                className="d-inline-block align-top"
                            />{' '}
                            <div className="d-flex flex-column" style={{ marginLeft: '.25rem' }} >
                                <div style={{ fontSize: '12px', lineHeight: '17px', textTransform: 'uppercase' }}>Корзина</div>
                                <div className={cart.count > 0 ? 'menu-basket-text color-pink' : 'menu-basket-text'}  >{cart.count > 0 ? cart.count + declOfNum(cart.count, [' Товар', ' Товара', ' Товаров']) : 'Нет товаров'} </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Navbar>
        </>
    )
})


export default NavBar