export function declOfNum(number, words) {
    return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
}



export function inPoly(x, y, name = '') {

    if (name.indexOf('мкр Центральный') > 0) {
        return { find: true, price: 0, pricePr: 200, name: "Город" }
    }
    const maps = [
                        /*Город*/                       { name: 'Город', cost: 0, costP: 200, points: [[58.202987942606, 68.249150066347], [58.199612723766, 68.247076419395], [58.198638978839, 68.247384574049], [58.196917997196, 68.25452401568], [58.198315061362, 68.259750156374], [58.201693757876, 68.271884469957], [58.201774468809, 68.276492505045], [58.202659445057, 68.278761653872], [58.204980029491, 68.291180494834], [58.2082034795, 68.29643516008], [58.209616520176, 68.30085636741], [58.212168754812, 68.304352430414], [58.215636163374, 68.306738345167], [58.2164084243, 68.306163101168], [58.220190155235, 68.306066541643], [58.22094305639, 68.306163101167], [58.221129863944, 68.306227474184], [58.223552612267, 68.306034355135], [58.224437579451, 68.306024048533], [58.229031486872, 68.306442050905], [58.237306504188, 68.306333435825], [58.243873330293, 68.277166641463], [58.243854455191, 68.270596495094], [58.243914780598, 68.264090721742], [58.242624901486, 68.260474747401], [58.238797456807, 68.261751122303], [58.238030780462, 68.256424076906], [58.237182042965, 68.25435859767], [58.226259911121, 68.254862496376], [58.209240142154, 68.249514846773], [58.208877725968, 68.249257354708], [58.202987942606, 68.249150066347]] },
                        /*Защитино, строитель*/         { name: '', cost: 300, costP: 300, points: [[58.2595608899, 68.248237953064], [58.262591661711, 68.255748138305], [58.263586783791, 68.264545783874], [58.263473703141, 68.269094810363], [58.26087274844, 68.275703773376], [58.252774636633, 68.290294990417], [58.243792156647, 68.289222106811], [58.243955540434, 68.276182529444], [58.243959841598, 68.263291270314], [58.243261360882, 68.260152523161], [58.238812385612, 68.261728180307], [58.237690010579, 68.253647884998], [58.241985807925, 68.250576474374], [58.2595608899, 68.248237953064]] },
                        /*Речпорт, Сузгун, Алемасова*/  { name: '', cost: 400, costP: 400, points: [[58.26396557025, 68.258185579013], [58.258175438078, 68.239689065646], [58.26765176124, 68.23578376932], [58.296223639563, 68.21299572153], [58.297714890028, 68.228874398898], [58.290348402859, 68.284406854342], [58.287955828483, 68.289559135285], [58.283551455316, 68.29312354849], [58.272781106232, 68.265145156553], [58.270171303181, 68.278488182725], [58.263128773916, 68.274193002414], [58.26396557025, 68.258185579013]] },


                        /*Подгора                    {name: '',cost: 400, costP: 400, points:[[58.183511306197,68.296605075626],[58.166971602331,68.280489280914],[58.167309743592,68.27846350653],[58.168520146979,68.276999020408],[58.16531404681,68.272495591472],[58.165095760632,68.272272968124],[58.165250228442,68.270463304074],[58.165985906117,68.262826227973],[58.167219032531,68.255967819522],[58.172631003907,68.241044739042],[58.175898790857,68.223933251583],[58.178347271029,68.215472458443],[58.194209737105,68.230783053788],[58.200031690008,68.239297695903],[58.20405782871,68.246154860545],[58.203003103664,68.249130979757],[58.19923021941,68.246690887415],[58.198344269327,68.247019043581],[58.196449982212,68.25496467335],[58.198177409736,68.259362355686],[58.200274728961,68.267032903245],[58.200079112214,68.267520780173],[58.19592775913,68.268816144572],[58.194774386258,68.269485284442],[58.192105444302,68.267492837402],[58.185806149977,68.272455703196],[58.188753858438,68.292267278096],[58.184857963408,68.300133073161],[58.183511306197,68.296605075626]]},*/
            /*Подгора*/ {
                            name: '', cost: 400, costP: 400, points: [
                                                        [58.204327, 68.241913],
                                                        [58.203248, 68.248776],
                                                        [58.199669, 68.247076],
                                                        [58.198485, 68.247576],
                                                        [58.197104, 68.255240],
                                                        [58.199328, 68.260942],
                                                        [58.168175, 68.283823],
                                                        [58.153624, 68.273436],
                                                        [58.148209, 68.252661],
                                                        [58.154392, 68.216342],
                                                        [58.173471, 68.209659],
                                                        [58.189855, 68.218957]
                                                    ]
                                                },



                        /**Менделеево**/                { name: '', cost: 500, costP: 500, points: [[58.2888318213683, 68.3058643341065], [58.2772802855328, 68.3228588104248], [58.2846358008135, 68.3416557312012], [58.2947866096547, 68.3419132232666], [58.2965908931868, 68.3309268951416], [58.2911777666406, 68.3045768737793]] },

                        /**Башково**/{ name: '', cost: 500, costP: 500, points: [[58.3251765064950, 68.2927322387695], [58.3174237023949, 68.2738494873047], [58.2840492206898, 68.2838058471680], [58.2961398309269, 68.3181381225586], [58.3026345728504, 68.3373641967774], [58.3161614571072, 68.3476638793945]] },
                        /**Винокурова**/{ name: '', cost: 650, costP: 650, points: [[58.3395057027784, 68.3231163024902], [58.3321615889271, 68.3181381225586], [58.3295479777659, 68.3438873291016], [58.3485598399363, 68.3560752868652], [58.3507216786996, 68.3298969268799]] },

                        /**Дурынина**/{ name: '', cost: 700, costP: 700, points: [[58.3385596142544, 68.3054351806641], [58.3338287916276, 68.3035469055176], [58.3327924316309, 68.3174514770508], [58.3381090870057, 68.3187389373779]] },

                        /**М.зоркольцево**/{ name: '', cost: 900, costP: 900, points: [[58.4249095233559, 68.3679199218750], [58.4195160362299, 68.4451675415039], [58.3867778384199, 68.3782196044922], [58.3952351209140, 68.3432006835938]] },

                        /**Сумкино**/{ name: '', cost: 900, costP: 900, points: [[58.1408141928035, 68.2360839843750], [58.0972954889081, 68.3212280273438], [58.1003798128005, 68.3569335937500], [58.1130771598473, 68.3744430541992], [58.1507797456366, 68.2686996459961]] },

                        /**Аэропорт**/{ name: '', cost: 1000, costP: 1000, points: [[58.0747899980259, 68.3013153076172], [58.0466381690348, 68.3023452758789], [58.0442760392774, 68.4111785888672], [58.0762423935470, 68.3432006835938]] },

                        /**Маслово**/{ name: '', cost: 1200, costP: 1200, points: [[58.0847740239048, 68.3809661865235], [58.0666191721041, 68.3830261230469], [58.0611709159450, 68.4489440917969], [58.0925777717127, 68.4437942504883]] },

                        /**Нижние аремзяны**/{ name: '', cost: 1400, costP: 1400, points: [[58.5036052586431, 68.6129665374756], [58.4877725319451, 68.5672187805176], [58.4821643307718, 68.5905647277832], [58.4875930833731, 68.6442089080811], [58.5012285624494, 68.6494445800781], [58.5101067184810, 68.6491870880127], [58.5119000127343, 68.6369991302490]] },

                        /**Абалак**/{ name: '', cost: 1000, costP: 1000, points: [[58.1529536773616, 68.5268783569336], [58.1185174958646, 68.5234451293945], [58.1112635299670, 68.6418914794922], [58.1534971395455, 68.6247253417969]] },

                        /**Вагай**/{ name: '', cost: 2400, costP: 2400, points: [[57.9518489618125, 68.9697647094727], [57.9199536478761, 68.9457321166992], [57.9066403663116, 69.0428924560547], [57.9522133158843, 69.0435791015625]] },

                        /**Юрты иртышатские, Сабанаки**/{ name: '', cost: 900, costP: 900, points: [[58.1047790485135, 68.2525634765625], [58.1049604486441, 68.2789993286133], [58.0761516205582, 68.2911872863770], [58.0735644933404, 68.2676696777344]] },

                        /**Сибиряк**/{ name: '', cost: 700, costP: 700, points: [[58.1540405934308, 68.4516906738281], [58.1553086202238, 68.4757232666016], [58.1440759535555, 68.4781265258789], [58.1377333666028, 68.4516906738281]] },

                        /**Медянки татарские**/{ name: '', cost: 900, costP: 900, points: [[58.152128, 68.216591], [58.136150, 68.207231], [58.143966, 68.227676]] },

                        /**Медянки русские**/{ name: '', cost: 900, costP: 900, points: [[58.133034, 68.208864], [58.133464, 68.230057], [58.121608, 68.231683], [58.120901, 68.208632]] },

                        /**Восточный**/{ name: '', cost: 400, costP: 400, points: [[58.196103, 68.318147], [58.187707, 68.331454], [58.185654, 68.324875], [58.193077, 68.309225]] },
                        /**18 мкр**/{ name: '', cost: 200, costP: 200, points: [[58.227325, 68.306267], [58.221030, 68.306396], [58.221166, 68.316288], [58.228004, 68.316266]] },
    ]
    if (x === "58.201698" && y === "68.253762") return { find: true, price: 500, pricePr: 500, name: '' };
    let ic = 0;
    let ip1 = 0;
    let ip2 = 0;
    let ipn = '';
    maps.map(gorod => {
        var xp = []; // Массив X-координат полигона
        var yp = []; // Массив Y-координат полигона
        var p1 = 0;
        var p2 = 0;
        var pn = '';
        gorod.points.map(point => {
            xp.push(point[0])
            yp.push(point[1])
            p1 = gorod.cost
            p2 = gorod.costP
            pn = gorod.name
        })

        var c = 0;
        let npol = xp.length;
        let j = npol - 1;

        for (var i = 0; i < npol; i++) {
            if ((((yp[i] <= y) && (y < yp[j])) || ((yp[j] <= y) && (y < yp[i]))) &&
                (x > (xp[j] - xp[i]) * (y - yp[i]) / (yp[j] - yp[i]) + xp[i])) {
                c = !c
            }
            j = i;
        }
        if (c) {
            ic = c
            ip1 = p1
            ip2 = p2
            ipn = pn
        }
    })

    return { find: ic, price: ip1, pricePr: ip2, name: ipn };
}





export function getDateTime(now) {
    return new Date(Math.floor(new Date(now).getTime() / (3600 * 1000)) * (3600 * 1000));
}