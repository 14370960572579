import { Observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Col, Container , Row, Button} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Context } from '..';
import CategoryItem from '../components/CategoryItem';
import TypeButton from '../components/TypeButton';

const Privacy = () => {

    return (
        <>

            <Container className='mh100 container-fluid container-md '>
            
            <p>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</p>
<p>г.Тобольск                                                                                                                                                                      19 февраль 2024 г. </p>
Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Интернет-магазин «Презент», расположенный на доменном имени https://презент72.рф, может получить о Пользователе во время использования сайта Интернет-магазина, программ и продуктов Интернет-магазина.
<p>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</p>
1.1 В настоящей Политике конфиденциальности используются следующие термины:
<p>1.1.1. «Администрация сайта Интернет-магазина цветов  (далее – Администрация сайта)» – уполномоченные сотрудники на управления сайтом, действующие от лица ИП Зуевой К.А, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</p>
1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).
<p>1.1.3. «Обработка персональных данных» - любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.
<p>1.1.5. «Пользователь сайта Интернет-магазина (далее Пользователь)» – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт интернет-магазина.</p>
1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.
<p>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
2. ОБЩИЕ ПОЛОЖЕНИЯ
<p>2.1. Использование Пользователем сайта Интернет-магазина означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</p>
2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта Интернет-магазина.
<p>2.3. Настоящая Политика конфиденциальности применяется только к сайту Интернет-магазина «Презент». Интернет-магазин не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте Интернет-магазина.</p>
2.4. Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта Интернет-магазина.
<p>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</p>
3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта интернет-магазина по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при регистрации на сайте интернет-магазина или при оформлении заказа для приобретения Товара.
<p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения регистрационной формы на Сайте интернет-магазина «Презент», в разделах https://презент72.рф/cart включают в себя следующую информацию:</p>
3.2.1. Фамилию, имя, отчество Пользователя;
<p>3.2.2. контактный телефон Пользователя;</p>
3.2.3. адрес электронной почты (e-mail);
<p>3.2.4. адрес доставки Товара;</p>
3.2.5 Фамилию, имя, отчество Получателя;
<p>3.2.6 Контактный телефон Получателя;</p>
3.3. Интернет-магазин защищает Данные, которые автоматически передаются в процессе просмотра рекламных блоков и при посещении страниц, на которых установлен статистический скрипт системы ("пиксель"):
<p>·         IP адрес;</p>
·         информация из cookies;
<p>·         информация о браузере (или иной программе, которая осуществляет доступ к показу рекламы);</p>
·         время доступа;
<p>·         адрес страницы, на которой расположен рекламный блок;</p>
·         реферер (адрес предыдущей страницы).
<p>3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта Интернет-магазина, требующим авторизации.</p>
3.3.2. Интернет-магазин осуществляет сбор статистики об IP-адресах своих посетителей. Данная информация используется с целью выявления и решения технических проблем, для контроля законности проводимых финансовых платежей.
<p>3.4. Любая иная персональная информация неоговоренная выше (история покупок, используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.</p>
4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ
<p>4.1. Персональные данные Пользователя Администрация сайта интернет-магазина может использовать в целях:</p>
4.1.1. Идентификации Пользователя, зарегистрированного на сайте Интернет-магазина, для оформления заказа и (или) заключения Договора купли-продажи товара дистанционным способом c Интернет –магазином Презент
<p>4.1.2. Предоставления Пользователю доступа к персонализированным ресурсам Сайта интернет-магазина.</p>
4.1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта интернет-магазина, оказания услуг, обработка запросов и заявок от Пользователя.
<p>4.1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.</p>
4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
<p>4.1.6. Уведомления Пользователя Сайта интернет-магазина о состоянии Заказа.</p>
4.1.7. Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа, определения права на получение кредитной линии Пользователем.
<p>4.1.8. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем связанных с использованием Сайта интернет-магазина.</p>
4.1.9. Осуществления рекламной деятельности с согласия Пользователя.
<p>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</p>
5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.
<p>5.2. Пользователь соглашается с тем, что Администрация сайта вправе передавать персональные данные третьим лицам, в частности, курьерским службам, организациями почтовой связи, операторам электросвязи, исключительно в целях выполнения заказа Пользователя, оформленного на Сайте интернет-магазина «Презент», включая доставку Товара.</p>
5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.
<p>5.4. При утрате или разглашении персональных данных Администрация сайта информирует Пользователя об утрате или разглашении персональных данных.</p>
5.5. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
<p>5.6. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>
6. ОБЯЗАТЕЛЬСТВА СТОРОН
<p>6.1. Пользователь обязан:</p>
6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом интернет-магазина.
<p>6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.</p>
6.2. Администрация сайта обязана:
<p>6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики конфиденциальности.</p>
6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3. настоящей Политики Конфиденциальности.
<p>6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.</p>
6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
<p>7. ОТВЕТСТВЕННОСТЬ СТОРОН</p>
7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности.
<p>7.2. В случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация:</p>
7.2.1. Стала публичным достоянием до её утраты или разглашения.
<p>7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта.</p>
7.2.3. Была разглашена с согласия Пользователя.
<p>8. РАЗРЕШЕНИЕ СПОРОВ</p>
8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта Интернет-магазина и Администрацией сайта, обязательным является предъявление претензии (письменного предложения о добровольном урегулировании спора).
<p>8.2 .Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>
8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством Российской Федерации.
<p>8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской Федерации.</p>
9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ
<p>9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</p>
9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте интернет-магазина, если иное не предусмотрено новой редакцией Политики конфиденциальности.
<p>9.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать по телефону: +7 (912) 393-91-50</p>
9.4. Действующая Политика конфиденциальности размещена на странице по адресу https://презент72.рф/privacy/ 
<p>Обновлено «19» февраля 2024 г.</p>


            
            
            </Container>
        </>

    );

}

export default Privacy;