import { makeAutoObservable } from "mobx"

export default class CartStore {
    constructor() {
        this._discount = 0
        const ls = localStorage.getItem('cart')
        this._product = ls === null ? {} : JSON.parse(ls)

        const oi = localStorage.getItem('orderId')
        this._orderId = oi === null ? '' : oi


        this._video = ''
        
        this._recipientPhone = localStorage.getItem('phone') === null ? "+7 " : localStorage.getItem('phone')
        this._recipientName = localStorage.getItem('name') === null ? "" : localStorage.getItem('name')
        this._samFName = localStorage.getItem('samFName') === null ? "" : localStorage.getItem('samFName')

        this._cardText = localStorage.getItem('cardText') === null ? "" : localStorage.getItem('cardText')
        this._address = localStorage.getItem('address') === null ? "" : localStorage.getItem('address')
        makeAutoObservable(this)
    }

    setAddress(d) {
        this._address = d
        localStorage.setItem('address', d);
    }
    get address() {
        return this._address
    }

    setOrderId(d) {
        this._orderId = d
        localStorage.setItem('orderId', d);
    }
    get orderId() {
        return this._orderId
    }
    get video() {
        return this._video
    }
    setVideo(d) {
        this._video = d
    }

    setSamFName(d) {
        this._samFName = d
        localStorage.setItem('samFName', d);
    }
    get SamFName() {
        return this._samFName
    }

    setCardText(d) {
        this._cardText = d
        localStorage.setItem('cardText', d);
    }
    get cardText() {
        return this._cardText
    }

    setRecipientPhone(d) {
        this._recipientPhone = d
        localStorage.setItem('phone', d);
    }
    get recipientPhone() {
        return this._recipientPhone
    }
    setRecipientName(d) {
        this._recipientName = d
        localStorage.setItem('name', d);
    }
    get recipientName() {
        return this._recipientName
    }

    setDiscount(d) {
        this._discount = d
    }
    setProduct(id, data) {
        if (typeof this._product[id] === 'undefined')
            this._product[id] = { id: id, count: 1, data: data }
        else
            this._product[id] = { id: id, count: this._product[id].count + 1, data: this._product[id].data, }


        localStorage.setItem('cart', JSON.stringify(this._product));
    }
    incrProduct(id) {
        if (typeof this._product[id] !== 'undefined')
            if (this._product[id].count > 1)
                this._product[id] = { id: id, data: this._product[id].data, count: this._product[id].count - 1 }
            else
                delete this._product[id]
        localStorage.setItem('cart', JSON.stringify(this._product));
    }

    delProduct(id) {
        if (typeof this._product[id] !== 'undefined')
            delete this._product[id]

        localStorage.setItem('cart', JSON.stringify(this._product));
    }

    clearCart() {
        this._product = {}
        localStorage.setItem('cart', JSON.stringify(this._product));
    }
    checkID(id) {
        return typeof this._product[id] !== 'undefined' ? this._product[id].count : 0
    }
    get products() {
        return this._product
    }
    get discount() {
        return this._discount
    }
    get count() {
        return Object.keys(this._product).length
    }

    setProduct1C(name,id,count,price,sklad,maxCount) {
        

        if(typeof name !== 'undefined'){
            this._product[id] = { id: id, count: count, data: {"photo":"https://презент72.рф/img/"+id+".jpg","price":price,"name":name,"maxCount":maxCount,"address":sklad == '00-000001' ? '10 мкр' : '9 мкр'} }
            localStorage.setItem('cart', JSON.stringify(this._product));
        }
            


        
    }
}