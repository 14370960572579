import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import NavBar from "./components/NavBar";
import './App.css';
import Footer from "./components/Footer";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { fetchCategories, fetchItems } from "./http/item";
import { Context } from ".";


const App = observer(  () => {

  const {cart, product} = useContext(Context)
  useEffect(() => {
    /*fetchCategories().then(data => {
      product.setTypes(data)*/
      
      fetchItems(product.selectedType).then(data => {
        /*Object.keys(cart.products).map(id=>{
            let fined = false
            data['items'].map(idp => {

              if(idp['id'] == id)
                fined = true.......
            })
            if(!fined)
              cart.delProduct(id)
        })*/


        product.setTypes(data['categories'])
        
        product.setProducts(data['items'])
      })

  /*  }
      )*/


  }, [product.selectedType])

  return (
    <BrowserRouter>
      <NavBar/>
      <AppRouter />
      <Footer/>
    </BrowserRouter>
      
  );
})

export default App;
