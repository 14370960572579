import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react'
import { Accordion, Button, Container, Image, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Context } from '..';
import { checkOrder } from '../http/item';
import { ORDERS_ROUTE } from '../utils/consts';

const Orders = observer(({ props }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { orders, cart } = useContext(Context)
    const navigate = useNavigate()
    const [modalShow, setModalShow] = useState(searchParams.get("new_order") !== null && searchParams.get("sum") !== null);


    const [openModalShow, setOpenModalShow] = useState(searchParams.get("openModal") === '1');


    useEffect(() => {
        if (modalShow) {
            if (cart.orderId !== '') {
                checkOrder({ id: cart.orderId }).then(data => {
                    if (cart.count > 0 && data.data.status == 'success') {
                        orders.addOrder(searchParams.get("new_order"), cart.products, 'success', searchParams.get("sum"))
                        cart.clearCart()
                        cart.setOrderId('')
                        document.location.href = (ORDERS_ROUTE + '?openModal=1')
                    }
                })
            } else {
                if (cart.count > 0) {
                    orders.addOrder(searchParams.get("new_order"), cart.products, 'new', searchParams.get("sum"))
                    cart.clearCart()
                    document.location.href = (ORDERS_ROUTE + '?openModal=1')
                }
            }


        }
    }, [])



    return (

        <Container className='mh100 my-4'>
            <Row>
                <Accordion defaultActiveKey="0">

                    {orders.orders.map((el, i) => {
                        return (
                            <Accordion.Item key={i} eventKey={i}>
                                <Accordion.Header>{el.orderNumber}</Accordion.Header>
                                <Accordion.Body>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th >Фото</th>
                                                <th >Наименование</th>
                                                <th >Количество</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                Object.keys(el.items).map((id, index) => {

                                                    return (
                                                        <tr key={`${i}_${index}`}>
                                                            <td>{index + 1}</td>
                                                            <td><Image style={{ maxWidth: '150px' }} src={el.items[id].data.photo} /></td>
                                                            <td>{el.items[id].data.name} </td>
                                                            <td>{el.items[id].count}</td>
                                                        </tr>
                                                    )
                                                })

                                            }

                                        </tbody>

                                    </Table>
                                    <div className="d-flex justify-content-between">
                                        <div><b>Сумма: {el.amount} руб.</b> </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                        )

                    })}


                </Accordion>



                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={openModalShow}
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Заказ сформирован
                        </Modal.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => setOpenModalShow(false)}></button>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='d-flex align-items-center justify-content-evenly'>
                            <div>
                                <svg id="successAnimation" className="animated" xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
                                    <path id="successAnimationResult" fill="#D8D8D8" d="M35,60 C21.1928813,60 10,48.8071187 10,35 C10,21.1928813 21.1928813,10 35,10 C48.8071187,10 60,21.1928813 60,35 C60,48.8071187 48.8071187,60 35,60 Z M23.6332378,33.2260427 L22.3667622,34.7739573 L34.1433655,44.40936 L47.776114,27.6305926 L46.223886,26.3694074 L33.8566345,41.59064 L23.6332378,33.2260427 Z" />
                                    <circle id="successAnimationCircle" cx="35" cy="35" r="24" stroke="#979797" strokeWidth="2" strokeLinecap="round" fill="transparent" />
                                    <polyline id="successAnimationCheck" stroke="#979797" strokeWidth="2" points="23 34 34 43 47 27" fill="transparent" />
                                </svg>
                            </div>
                            <div>
                                <div>Заказ принят. Покупка прошла успешно!</div>
                                <div>Остались вопросы? Звони <a href='tel:8-912-393-91-50'>8-912-393-91-50</a></div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setOpenModalShow(false)}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>

            </Row>
        </Container>

    );

})

export default Orders