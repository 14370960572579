import axios from "axios";

const $host = axios.create({
    baseURL: 'https://xn--72-mlcaj1bkiw.xn--p1ai/api/'
})




export {
    $host
}