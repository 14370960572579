export const CATEGORY_ROUTE = '/'
export const CART_ROUTE = '/cart'
export const PRODUCT_ROUTE = '/:slug/:productid'
export const PAYMENT_ROUTE = '/payment'
export const DELIVERY_ROUTE = '/delivery'
export const CONTACT_ROUTE = '/contact'
export const REVIEWS_ROUTE = '/reviews'
export const ORDERS_ROUTE = '/orders'
export const ORDERINFO_ROUTE = '/order/:orderid'
export const CARE_ROUTE = '/care'
export const OFERTA_ROUTE = '/oferta'
export const PRIVACY_ROUTE = '/privacy'
