import React, { useContext } from 'react';
import { observer } from "mobx-react-lite"
import { Context } from '..';
import { useParams, useNavigate } from 'react-router-dom';
import { CATEGORY_ROUTE } from '../utils/consts';
import { Card, Col, Image, Row } from 'react-bootstrap';
import cartImg from '../assets/img/basket.png'
import cartFullImg from '../assets/img/basket-full.png'

const CategoryItem = observer(() => {
    const { product, cart } = useContext(Context)
    const param = useParams()
    const navigate = useNavigate()


    /*<Button key={type.id} onClick={   ()=> {  navigate(CATEGORY_ROUTE + '' + type.id)}   } variant={params.slug == type.id ? "pink" : "outline pink"}>{type.name}</Button>*/
    return (


        <Row>
            {
                product.products.length === 0 ? 
                [...Array(20)].map(() => {

                    return(

                   
            <Col md={3} xs={6} className='bg-white p-0'>

            <svg
  role="img"
  width="100%"
  height="424"
  aria-labelledby="loading-aria"
  viewBox="0 0 300 424"
  preserveAspectRatio="none"
>
  <title id="loading-aria">Loading...</title>
  <rect
    x="0"
    y="0"
    width="100%"
    height="100%"
    clipPath="url(#clip-path)"
    style={{fill: 'url("#fill")'}}
  ></rect>
  <defs>
    <clipPath id="clip-path">
        <rect x="0" y="0" rx="2" ry="2" width="400" height="300" /> 
        <rect x="43" y="313" rx="0" ry="0" width="217" height="14" /> 
        <rect x="43" y="371" rx="0" ry="0" width="217" height="34" /> 
        <rect x="43" y="344" rx="0" ry="0" width="217" height="14" />
    </clipPath>
    <linearGradient id="fill">
      <stop
        offset="0.599964"
        stopColor="#f3f3f3"
        stopOpacity="1"
      >
        <animate
          attributeName="offset"
          values="-2; -2; 1"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop
        offset="1.59996"
        stopColor="#ecebeb"
        stopOpacity="1"
      >
        <animate
          attributeName="offset"
          values="-1; -1; 2"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop
        offset="2.59996"
        stopColor="#f3f3f3"
        stopOpacity="1"
      >
        <animate
          attributeName="offset"
          values="0; 0; 3"
          keyTimes="0; 0.25; 1"
          dur="2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
    </linearGradient>
  </defs>
</svg>

            </Col>

)
})
            :
            product.products.map(p => {
                
                    return (


                        <Col md={3} xs="6" key={p.id + p.address} className={"mt-3"} >
                            <Card className='br7 text-center' style={{ cursor: 'pointer' }} border={"light"}>
                                <div onClick={() => navigate('/' + p.category + '/' + p.id)} className='CategoryItem-img' style={{ backgroundImage: `url(https://презент72.рф/img/${p.id}_thumb.jpg?t=${Math.round(new Date().getTime()/(1000*60*5))})` }}></div>

                                <div className="text-black-50 mt-3 d-flex flex-column justify-content-center align-items-center">

                                    <div onClick={() => navigate('/' +  p.category + '/' + p.id)} style={{ WebkitLineClamp: '1', display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden' }}>{p.name} </div>
                                    <div hidden style={{ fontSize: '12px' }} onClick={() => navigate('/' +  p.category + '/' + p.id)}> {p.address}</div>
                                    <div className='text-black' onClick={() => navigate('/' +  p.category + '/' + p.id)}><b>{p.price} ₽</b></div>


                                </div>
                                <div
                                     onClick={() => {
                                        if (cart.checkID(p.id) !== 0)
                                            navigate('/' +  p.category + '/' + p.id)
                                        else if (p.count > cart.checkID(p.id)){
                                            cart.setProduct(p.id, { photo: p.img, price: p.price, name:p.name, maxCount: p.count, address: p.address })

                                            product.setShowModal(p.category)
                                            product.setShowModalOt(p.category)
                                        }
                                    }}
                                    className={`d-flex flex-row align-items-center justify-content-around py-2 mx-3 text-white ${cart.checkID(p.id) == 0 ? 'bg-black' : 'bg-green'}`}>

                                    <div>
                                        {cart.checkID(p.id) == 0 ? 'В корзину' : 'В корзинe'}
                                    </div>

                                </div>

                            </Card>
                        </Col>


                    )
            })
        }

        </Row>

    )
})
export default CategoryItem