import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Context } from '..';
import CategoryItem from '../components/CategoryItem';

const Home = observer( () => {
    const {product} = useContext(Context)
    const [searchParams, setSearchParams] = useSearchParams();
    
    if(searchParams.get("code") !== null){
        localStorage.setItem('promocode', searchParams.get("code"));
    }
    


        return( 
        <>

            <Container className='mh100 container-fluid container-md '>
            

            <CategoryItem />
            
            
            </Container>
        </>
        )

})

export default Home;