import { Observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react'
import { Col, Container , Row, Button} from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Context } from '..';
import CategoryItem from '../components/CategoryItem';
import TypeButton from '../components/TypeButton';

const Care = () => {

    return (
        <>

            <Container className='mh100 container-fluid container-md '>
            
            <br/>
            <h4>КАК ПРОДЛИТЬ РАДОСТЬ ОТ ЦВЕТОЧНОГО ПОДАРКА: ПРОСТЫЕ ЛАЙФХАКИ ФЛОРИСТОВ «ПРЕЗЕНТ»</h4><br/><br/>
            <h5>Как ухаживать за букетом цветов </h5>
            <ul>
                <li>1.	Подберите подходящую вазу для цветов - она должна быть достаточно широкой, чтобы в ней стволы цветов не были зажатыми.</li>
                <li>2.	Возьмите секатор или острый нож (НЕ НОЖНИЦЫ!) - и подрежьте по косой стебель каждого цветка: если этого не сделать, цветы не смогут впитывать воду и быстро завянут.</li>
                <li>3.	Наполните вазу холодной водой из-под крана, растворите внутри пакетик со средством, продлевающим жизнь срезанных цветов - мы добавили его в подарок.</li>
                <li>4.	Разместите букет вдали от батарей и других отопительных приборов, прямых солнечных лучей, сквозняков и еды. Не ставьте букет на подоконнике!</li>
                <li>5.	Ежедневно заменяйте воду в вазе и обновляйте срез стебля ножом или секатором.</li>

            </ul>

            <br/><br/>
            <h5>Как ухаживать за цветочной композицией </h5>
            <ul>
                <li>1.	Разместите композицию вдали от батарей и других отопительных приборов, прямых солнечных лучей, сквозняков и фруктов. Не ставьте композицию на подоконнике!</li>
                <li>2.	Ежедневно подливайте свежую холодную воду из-под крана в центр композиции, словно поливаете обычный комнатный цветок - растения размещены на специальной губке, которая отлично впитывает и удерживает влагу.</li>
            </ul>



            <br/><br/>
            <h5>Если в букете ГОРТЕНЗИЯ</h5>
            <ul>
                <li>1.	Прочтите правила ухода за букетом цветов.</li>
                <li>2.	Гортензию нужно, не просто подрезать, а расщепить ствол на 4 части, так она больше впитывает влаги.</li>
                <li>3.	Гортензия обожает воду и наилучшим образом сохраняет внешний вид, если постоянно находится в достаточном количестве воды и дополнительно опрыскивается сверху. </li>
                <li>4.	Если в вашем букете или композиции присутствует не только гортензия, то опрыскивание производите аккуратно, что бы не попасть на другие цветы.</li>
                <li>5.	Бутоны гортензии стоит опрыскивать 1-2 раза в день - так они будут выглядеть свежими еще много дней!</li>
            </ul>






<br/><br/>
            <h5>Если  букет из КУСТОВЫХ РОЗЫ</h5>
            <ul>
                <li>1.	Прочтите правила ухода за букетом цветов</li>
                <li>2.	Подберите высокую и широкую вазу. Высота вазы должна соответствовать высоте роз , как минимум на 50%</li>
                <li>3.	Наполните вазу холодной водой из-под крана почти до самого верха - кустовые розы пьют всем стеблем! Если воды будет недостаточно, цветы быстро завянут.</li>
                <li>4.	Подрежьте стебли по косой острым ножом или секатором - обновляйте срез ежедневно, перед каждой заменой воды в вазе.</li>
                <li>5.	Разместите букет вдали от батарей и других отопительных приборов, прямых солнечных лучей, сквозняков и фруктов. Не ставьте композицию на подоконнике!</li>
            </ul>
 



            <br/><br/>
            <h5>Если в букет из ТЮЛЬПАНОВ</h5>
            <ul>
                <li>1.	Прочтите правила ухода за букетом цветов </li>
                <li>2.	Подберите широкую и глубокую вазу.</li>
                <li>3.	Наполните вазу холодной водой на ⅓ - можно даже добавить в нее лед.</li>
                <li>4.	Ровной линией подрежьте стебли тюльпанов острым ножом или секатором.</li>
                <li>5.	Меняйте воду в вазе ежедневно.</li>
                <li>6.	Разместите букет вдали от батарей и других отопительных приборов, прямых солнечных лучей, сквозняков и фруктов. Не ставьте композицию на подоконнике!</li>
            </ul>









<br/><br/>
            <h5>Если в букете ОРХИДЕИ</h5>
            <ul>
                <li>1.	Прочтите правила ухода за букетом цветов</li>
                <li>2.	Обратите внимание - стебли орхидей размещены в специальных пластиковых капсулах.</li>
                <li>3.	Время от времени проверяйте количество воды в капсуле - и восполняйте недостаток водой из-под крана, аккуратно снимая колпачок сверху обновляя срез орхидеи</li>
                <li>4.	Разместите букет вдали от батарей и других отопительных приборов, прямых солнечных лучей, сквозняков и фруктов. Не ставьте композицию на подоконнике!</li>

            </ul>

 





<br/><br/>
            <h5>Если  букет из РОЗ</h5>
            <ul>
                <li>1.	Подберите высокую и широкую вазу. Высота вазы должна соответствовать высоте роз , как минимум на 50%</li>
                <li>2.	Наполните вазу холодной водой на ⅓ - можно даже добавить в нее лед</li>
                <li>3.	Подрежьте стебли по косой острым ножом или секатором - обновляйте срез ежедневно, перед каждой заменой воды в вазе.</li>
                <li>4.	Разместите букет вдали от батарей и других отопительных приборов, прямых солнечных лучей, сквозняков и фруктов. Не ставьте на подоконнике!</li>
            </ul>







<br/><br/>
            <h5>Если  букет из Хризантем</h5>
            <ul>
                <li>1.	Подберите высокую и широкую вазу. Высота вазы должна соответствовать высоте хризантем , как минимум на 50%</li>
                <li>2.	Наполните вазу холодной водой, что бы стебли закрывались 5-7 см. Хризантемы не любят много воды</li>
                <li>3.	Подрежьте стебли по косой острым ножом или секатором ОЧЕНЬ аккуратно, хризантемы очень чувствительны к любым малейшим ударом, об любую поверхность - обновляйте срез ежедневно, перед каждой заменой воды в вазе.</li>
                <li>4.	Разместите букет вдали от батарей и других отопительных приборов, прямых солнечных лучей, сквозняков и фруктов. Не ставьте на подоконнике!</li>

            </ul>





            
            
            </Container>
        </>

    );

}

export default Care;