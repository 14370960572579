import React from 'react'
import Cart from "./pages/Cart";
import Category from "./pages/Category";
import Contact from './pages/Contact';
import Delivery from './pages/Delivery';
import Home from './pages/Home';
import OrderInfo from './pages/OrderInfo';
import Orders from './pages/Orders';
import Payment from './pages/Payment';
import Product from "./pages/Product";
import Reviews from './pages/Reviews';
import { CART_ROUTE, PRODUCT_ROUTE, CATEGORY_ROUTE, DELIVERY_ROUTE, PAYMENT_ROUTE, REVIEWS_ROUTE, CONTACT_ROUTE, ORDERS_ROUTE, ORDERINFO_ROUTE, CARE_ROUTE, PRIVACY_ROUTE, OFERTA_ROUTE } from "./utils/consts";
import Care from './pages/Care';
import Privacy from './pages/Privacy';
import Oferta from './pages/Oferta';

export const publicRoutes = [
    {
        path: '/',
        Component: <Home/>
    },
    {
        path: DELIVERY_ROUTE,
        Component: <Delivery/>
    },
    {
        path: PAYMENT_ROUTE,
        Component: <Payment/>
    },
    {
        path: REVIEWS_ROUTE,
        Component: <Reviews/>
    },
    {
        path: CONTACT_ROUTE,
        Component: <Contact/>
    },
    {
        path: ORDERS_ROUTE,
        Component: <Orders/>
    },
    {
        path: CATEGORY_ROUTE + ':slug',
        Component: <Category/>
    },
    {
        path: PRODUCT_ROUTE ,
        Component: <Product/>
    },
    {
        path: CART_ROUTE,
        Component: <Cart/>
    },
    {
        path: ORDERINFO_ROUTE,
        Component: <OrderInfo/>
    },
    {
        path: CARE_ROUTE,
        Component: <Care/>
    },
    {
        path: PRIVACY_ROUTE,
        Component: <Privacy/>
    },
    {
        path: OFERTA_ROUTE,
        Component: <Oferta/>
    }
]