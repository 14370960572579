import { makeAutoObservable } from "mobx"

export default class ProductStore{
    constructor(){
        this._types = []

        this._products = []

        this._selectedType = window.location.pathname.split('/').length > 2 ? null : 0

        this._showModal = ''

        this._showModalOt = ''
       
        this._otText =  localStorage.getItem('otText')  === null ? '' : localStorage.getItem('otText')

        makeAutoObservable(this)
    }

    setOtText(type){
        localStorage.setItem('otText', type);
        this._otText = type
    }
    get otText(){
       
        return this._otText 
    }

    setShowModalOt(type){
        console.log(type)
        console.log(['00-00000049'].includes(type))
        if (['00-00000049', ''].includes(type)){
            this._showModalOt = type
        }

    }
    get showModalOt(){
        return this._showModalOt 
    }

    setShowModal(type){
        console.log(type)
        console.log(['00-00000053', '00-00018584', '00-00000001', ''].includes(type))
        if (['00-00000053', '00-00018584', '00-00000001', ''].includes(type)){
            this._showModal = type
        }
        
        
    }
    get showModal(){
        return this._showModal 
    }

    setSelectedType(type){
        this._selectedType = type
    }

    setTypes(types){
        types.sort(function(a, b) {
            return a.sort - b.sort;
          })

        this._types = types
    }

    setProducts(products){
        this._products = products 
        /*products.sort(function(a, b) {
            
            return (a.hit ? -1000 : 1 )*a.price - (b.hit ? -1000 : 1 )*b.price;
          })
        this._products = products*/
    }
    getFirstTypeId(){
        return this._types.length == 0 ? '' : this._types[0].id
        
    }
    getType(id){
        let tws = null
        this._types.map(element => {
            if(element.id == id)
                tws = element
        });
        return tws
    }
    getProduct(id){
        let tws = null
        this._products.map(element => {
            if(element.id == id)
                tws = element
        });
        return tws
    }

    get types(){
        return this._types 
    }

    get selectedType(){
        return this._selectedType
    }
    get products(){
        return this._products 
    }
}