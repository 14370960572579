import React from 'react'
import { Container, Row } from 'react-bootstrap'

const Reviews = () => {
    return (

        <Container className='mh100 '>
            <Row>
                Скоро сделаем
            </Row>
        </Container>

    );

}

export default Reviews;