import axios from "axios";
import {$host} from "./index";


export const fetchItems = async (cat) => {
    const {data} = await $host.get('api.php?method=getProducts&category=' + cat)
    return data
}
export const fetchOrder = async (cat) => {
    const {data} = await $host.get('api.php?method=ПолучитьЗаказСайт&id=' + cat)
    return data
}

export const fetchCategories = async () => {
    const {data} = await $host.get('api.php?method=ПолучитьКатегории')
    return data
}

export const fetchHoliday = async () => {
    const data = await $host.get('api.php?method=Праздник')
    return data
}
export const fetchDeliveryCost = async () => {
    const data = await $host.get('api.php?method=ПолучитьСтоимостьДоставки')
    return data
}
export const fetchCard = async (phone) => {
    const data = await $host.get('api.php?method=ПроверитьСкидку&phone='+phone)
    return data
}
export const sendOrder = async (param = {}) => {
    const data = await axios({
        method: 'post',
        url:  'https://xn--72-mlcaj1bkiw.xn--p1ai/api/sendmail.php',
        data: param
      });
    /*const data = await $host.post('sendmail.php', param)*/
    return data
}
export const checkOrder = async (param = {}) => {
    const data = await axios({
        method: 'post',
        url:  'https://xn--72-mlcaj1bkiw.xn--p1ai/api/checkOrder.php',
        data: param
      });
    /*const data = await $host.post('sendmail.php', param)*/
    return data
}

export const checkPromo = async (promo) => {
    const data = await $host.get('api.php?method=ПроверитьПромокод&promo='+promo)
    return data
}

export const checkProduct = async (param = []) => {
    const data = await axios({
        method: 'post',
        url:  'https://xn--72-mlcaj1bkiw.xn--p1ai/api/checkProduct.php?method=check',
        data: param
      });
    /*const data = await $host.post('sendmail.php', param)*/
    return data
}