import React from 'react'
import { Container, Row } from 'react-bootstrap';

const Payment = () => {
    return (

        <Container className='mh100 '>
            <Row>
        

            <h3 className='mt-4'>Оплата заказа</h3>
    <p>Вы можете произвести оплату заказа прямо сейчас!   </p>
    <p>
Цветы — яркие спутники нашей жизни. Бывает, что простой цветочек может существенно повлиять на нашу жизнь, что уже говорить о шикарном букете, который будет замечательным дополнением к презенту. Главное, верно выбрать и подарить. Сейчас в моде услуга доставка цветов, с помощью нее можно сделать букет неожиданным сюрпризом, отправив его с курьером и открыткой.  
</p>
<p>Заказать цветы с доставкой по городу Тобольску и Тобольскому району с оплатой онлайн в нашем интернет-магазине крайне просто.</p>
<p>
Вам нужно выбрать понравившийся букет или композицию, нажать на кнопку "ЗАКАЗАТЬ". После этого в корзине заказов (сверху справа) пройти по ссылке "оформить заказ", где необходимо заполнить требуемые поля.
</p>
<p><b>Оплата банковской картой</b></p>
<p>Для выбора оплаты товара с помощью банковской карты на соответствующей странице необходимо нажать кнопку «Оплата заказа банковской картой». Оплата происходит через ПАО СБЕРБАНК с использованием Банковских карт следующих платежных систем:</p>

                <ul style={{marginLeft: '2rem'}}>
                    <li> МИР</li>
                    <li> VISA International</li>
                    <li> Mastercard Worldwide</li>
                    <li> <li> Mastercard Worldwide</li></li>
                </ul>
            </Row>
            <p><b>Описание процесса передачи данных и шифрования</b></p>  
            <p>Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платежный шлюз ПАО СБЕРБАНК. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa или MasterCard SecureCode для проведения платежа также может потребоваться ввод специального пароля. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем МИР, Visa Int. и MasterCard Europe Sprl.</p>  
        </Container>

    );

}

export default Payment;