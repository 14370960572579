import React from 'react'
import { Container, Row } from 'react-bootstrap';

const Delivery = () => {
    return (

        <Container className='mh100 '>
            <Row>
				<h3  className='mt-4'>Доставка</h3>

<p ><span>Наша курьерская служба доставки цветов работает круглосуточно. Курьеры в любое время суток, в любую погоду по любому адресу в пределах города Тобольска и Тобольского района доставят Ваш заказ бережно и аккуратно!</span></p>
<p ><span>Мы лояльно относимся к своим клиентам и мы единственные в городе, кто предоставляет бесплатнную доставку цветов при покупке на сумму выше 1000 рублей по нагорной части города.</span></p>
<p ><span>&nbsp;</span></p>
<p><span><strong>Внимание! В праздничные дни доставка по нагорной части города при любом заказе платная!</strong></span></p>

<table border={0} cellpadding={0 } className="m-3">
<tbody>












 <tr>
  <td >
  <p  ><span
  >12 микрорайон (ул. Согласия, Уютная, Хрустальная, Янтарная, профессора Ахатова, Гавриила Хазова, Тихая, Лазурная)</span></p>
  </td>
  <td >
  <p><span>200</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>18 микрорайон (ул. Летняя, Западная, Апрельская, Зимняя, Грибная, Тенистая, Полярная, Восточная)</span></p>
  </td>
  <td >
  <p  ><span
  >200	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Абалак</span></p>
  </td>
  <td >
  <p  ><span
  >1000</span></p>
  </td>
 </tr>

<tr>
  <td >
  <p  ><span>Алемасова (база отдыха)</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Анисимова</span></p>
  </td>
  <td >
  <p  ><span
  >250	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Аэропорт</span></p>
  </td>
  <td >
  <p  ><span
  >1000</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Бабандино</span></p>
  </td>
  <td >
  <p  ><span
  >1200</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Байгара</span></p>
  </td>
  <td >
  <p  ><span
  >1400</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Башкова</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Бекерево (Левобережье), зима</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Бекерево (Левобережье), лето (паром)</span></p>
  </td>
  <td >
  <p  ><span
  >900	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Бизино</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>БСИ (кольцо, НХК, СИБУР, АБК, ТЭЦ)</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Вагай</span></p>
  </td>
  <td >
  <p  ><span
  >2400</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Верхние Аремзяны</span></p>
  </td>
  <td >
  <p  ><span
  >1000</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Винокурова</span></p>
  </td>
  <td >
  <p  ><span
  >650</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Ворогушино</span></p>
  </td>
  <td >
  <p  ><span
  >800</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Восточный микрорайон</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Дом отдыха</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Епанчина</span></p>
  </td>
  <td >
  <p  ><span
  >1200</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Ершовка</span></p>
  </td>
  <td >
  <p  ><span
  >350	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Ж/д вокзал</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Жуковка</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Загваздина</span></p>
  </td>
  <td >
  <p  ><span
  >1100</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Защитино</span></p>
  </td>
  <td >
  <p  ><span
  >300	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Зырянова</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Иртышский (Речпорт)</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Кладбище (за городом)</span></p>
  </td>
  <td >
  <p  ><span
  >450	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Ломаева</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Малая Зоркальцева</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Маслова</span></p>
  </td>
  <td >
  <p  ><span
  >1200</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Медведчиково</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Медянки (Русские, Татарские, Бизинский аэропорт)</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Менделеево</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Мостоотряд-87</span></p>
  </td>
  <td >
  <p  ><span
  >500	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Нижние Аремзяны</span></p>
  </td>
  <td >
  <p  ><span
  >1400</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Панин Бугор</span></p>
  </td>
  <td >
  <p  ><span
  >350	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Панушкова</span></p>
  </td>
  <td >
  <p  ><span
  > 1200</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Пионерная база (автодилеры)</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Подгорная часть (3-я Трудовая и далее включая Ленина 196 и 202)</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Подгорная часть (до 3-й Трудовой)</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Преображенка</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Прииртышский (Зверосовхоз)</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Сабанаки</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Савинский затон (Левобережье), зима</span></p>
  </td>
  <td >
  <p  ><span
  >600</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Савинский затон (Левобережье), лето (паром)</span></p>
  </td>
  <td >
  <p  ><span
  >1000</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Санникова</span></p>
  </td>
  <td >
  <p  ><span
  >1400</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Сетово (Левобережье), зима</span></p>
  </td>
  <td >
  <p  ><span
  >1300</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Сетово (Левобережье), лето (грунтовка, паром)</span></p>
  </td>
  <td >
  <p  ><span
  >1800</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Сибиряк</span></p>
  </td>
  <td >
  <p  ><span
  >700</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Соколовка</span></p>
  </td>
  <td >
  <p  ><span
  >350	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Строитель (ул. Прибыльского, Чулкова, Никитина, Батенькова, Терентьева, Тутолмина, Венгерского, пер. Газовиков, Заовражный, Лесной, база отдыха «Сказка»)</span></p>
  </td>
  <td >
  <p  ><span
  >300	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Сузгун</span></p>
  </td>
  <td >
  <p  ><span
  >400	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Сумкино</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Тренина (Левобережье), зима</span></p>
  </td>
  <td >
  <p  ><span
  >1000	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Тренина (Левобережье), лето (грунтовка, паром)</span></p>
  </td>
  <td >
  <p  ><span
  >1500</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Туртас</span></p>
  </td>
  <td >
  <p  ><span
  >2700</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Уват</span></p>
  </td>
  <td >
  <p  ><span
  >3000</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Усадьба</span></p>
  </td>
  <td >
  <p  ><span
  >300	</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Ушарово</span></p>
  </td>
  <td >
  <p  ><span
  >2000</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Шестакова</span></p>
  </td>
  <td >
  <p  ><span
  >1200</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Юрты Иртышатские</span></p>
  </td>
  <td >
  <p  ><span
  >900</span></p>
  </td>
 </tr>
<tr>
  <td >
  <p  ><span>Ярково</span></p>
  </td>
  <td >
  <p  ><span
  >3500	</span></p>
  </td>
 </tr>












































 </tbody>
</table>


<p  ><span >&nbsp;</span><span >Все
доставки на дальние расстояние обсуждаются заранее по телефону: <b>89123939150.</b></span></p>




<div>

</div>
</Row>
			</Container>

    );

}

export default Delivery;