import React, { createContext } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CartStore from './store/CartStore';
import ProductStore from './store/ProductStore';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ruLocale from "date-fns/locale/ru";
import OrdersStore from './store/OrdersStore';

export const Context = createContext(null)

ReactDOM.render(
    <Context.Provider value={{
        product: new ProductStore(),
        cart: new CartStore(),
        orders: new OrdersStore(),
      }} >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>

        <App />
      </LocalizationProvider>
    </Context.Provider>
  ,
  document.getElementById('root')
);

