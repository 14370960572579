import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Context } from '..';

const CartProduct = observer(({id, name, photo, count, maxCount, price, address}) => {
   const navigate = useNavigate()
    const {cart} = useContext(Context)

    return (

        
            <Row className='pt-3' key={id}>
                <Col xs={5}> <Image  src={ photo} width='100%' /> </Col>

                <Col xs={7} className='d-flex flex-column justify-content-between'> 
                    <div>
                        <h5>{name }</h5> 
                        
                    </div>
                    <div className='d-flex '>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <button className="btn btn-dark btn-sm" onClick={() => cart.incrProduct(id)}>-</button>
                                        </div>
                                        <input type="text" style={{maxWidth: '40px'}} className="form-control form-control-sm" value={count} onChange={() => {}} />
                                        <div className="input-group-prepend">
                                            <button className="btn btn-dark btn-sm" onClick={() => {    
                                                                                                        if(count < maxCount) cart.setProduct(id)

                                                                                                       
                                                                                                    }
                                                                                            
                                                                                            }>+</button>
                                        </div>
                                    </div>
                                    
                                    <h6 >
                                          {price}₽
                                        </h6>
                    </div>
                 </Col>

                 
                <Col xs={12} className=''> 
                    <hr />
                </Col>
            </Row>
           
          

        

    );

})

export default CartProduct;